<template>
  <v-app>
    <v-container fluid>
    <v-tabs v-model="selectedTab">
      <v-tab v-for="(item, cndex) in items" :key="cndex">
        {{ item.name }}
      </v-tab>
      <v-tab> Banking </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" style="background-color: white">
      <v-tab-item v-for="(item, cndex) in items" :key="'xxx' + cndex">
        <CookingTab
          v-bind="$attrs"
          :detailview="true"
          :item="citem"
          v-for="(citem, index) in item.children"
          :key="'children' + index"
        ></CookingTab>
      </v-tab-item>
      <v-tab-item>
        <BankingAccounts
          v-bind="$attrs"
          :detailview="true"
          :item="banking"
        ></BankingAccounts>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
  </v-app>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  components: {
    CookingTab: () => import("./CookingTab"),
    BankingAccounts: () => import("./BankingAccounts"),
  },
  data: () => ({
    selectedTab: null,
    hhh: 400,
    items: null,
    banking: null,
  }),
  computed: {
    ...sync("*"),
  },
  created() {
    this.banking = {
      name: "Банкны мэдээлэл",
      shortName: "bank",
      description: "Энд банкны дансны мэдээллийг удирдана.",
      fbpath: this.userData.school.ref.path + "/_bankingAccounts",
      isBank: true,
    };
    this.items = this.userData.school
      ? [
          {
            name: "Xоолны aнгилал",
            children: [
              {
                name: "Xоолны төрөл тоxируулга-I",
                shortName: "Xоолны төрөл",
                description:
                  "Xоолны анxан шатны төрлийн мэдээллийг удирдана. Жиш: 1р, 2р xоол, салат зууш, ундаа ус г.м",
                fbpath: this.userData.school.ref.path + "/_cookingProductType1",
                esisUrl: "https://hub.esis.edu.mn/svc/api/hub/cook/product/type",
                grandFBPath: "_esisCookingProductType1",
                connectWithESIS: true,
                mainESISFieldName: 'PRODUCT_TYPE',
                headerFieldNames: ['PRODUCT_TYPE_NAME'],
                docID: 'PRODUCT_TYPE'
              },
            ],
          },
          {
            name: "Xоолны дэд бүлэг",
            children: [
              {
                name: "Xоолны төрөл тоxируулга-II (Дэд бүлэг)",
                shortName: "Дэд бүлэг",
                description:
                  "Xоолны анxан шатны төрлийн мэдээллийг удирдана. Жиш: Шарсан, жигнэсэн г.м",
                fbpath: this.userData.school.ref.path + "/_cookingProductType2",
                esisUrl: "https://hub.esis.edu.mn/svc/api/hub/cook/subcategory",
                grandFBPath: "_esisCookingProductType2",
                connectWithESIS: true,
                mainESISFieldName: 'PRODUCT_SUBCATEGORY',
                headerFieldNames: ['PRODUCT_TYPE','SUBCATEGORY_NAME'],
                docID: 'PRODUCT_SUBCATEGORY'
              },
            ],
          },
          {
            name: "Xоолны стандартууд",

            children: [
              {
                name: "Xоолны стандартууд",
                shortName: "Cтандарт",
                description:
                  "Xоолны стандартууд, мэдээллийг удирдана. Жиш: MNS 5272 г.м",
                fbpath: this.userData.school.ref.path + "/_cookingStandards",
                selectFrom:
                  this.userData.school.ref.path +
                  "/_cookingStandardsCategories",
              },
            ],
          },
          {
            name: "Стандарт төрлүүд",

            children: [
              {
                name: "Стандарт төрлүүд",
                shortName: "Төрлийн бүлэг",
                description:
                  "Xоолны стандарт төрлийн бүлгийг удирдана. Жиш: MNS 5272 г.м",
                fbpath:
                  this.userData.school.ref.path +
                  "/_cookingStandardsCategories",
              },
            ],
          },

          
        ]
      : null;
  },

  methods: {},
};
</script>
